const sortOptionsLabels: Record<string, string> = {
  'sort:created-desc': 'Newest',
  'sort:created-asc': 'Oldest',
  'sort:comments-desc': 'Most commented',
  'sort:updated-desc': 'Recently updated',
  'sort:relevance': 'Best match',
  'sort:relevance-desc': 'Best match',
  'sort:reactions-+1-desc': 'Thumbs up',
  'sort:reactions--1-desc': 'Thumbs down',
  'sort:reactions-rocket-desc': 'Rocket',
  'sort:reactions-tada-desc': 'Hooray',
  'sort:reactions-eyes-desc': 'Eyes',
  'sort:reactions-heart-desc': 'Heart',
}

const sortDropdownOptionDisplayValues = {
  created: 'Created on',
  updated: 'Last updated',
  comments: 'Total comments',
  relevance: 'Best match',
}

const sortDropdownReactionLabels = {
  '+1': 'Thumbs up',
  '-1': 'Thumbs down',
  rocket: 'Rocket',
  tada: 'Hooray',
  eyes: 'Eyes',
  heart: 'Heart',
  smile: 'Laugh',
  thinking_face: 'Confused',
}

const sortDropdownMenuButtonLabels = {
  created: {
    asc: 'Oldest',
    desc: 'Newest',
  },
  comments: 'Comments',
  updated: 'Updated',
  relevance: 'Best match',
  reactions: 'Reactions',
  ...sortDropdownReactionLabels,
}

const sortKeyToQuery = {
  created: 'sort:created',
  comments: 'sort:comments',
  updated: 'sort:updated',
  reactions: 'sort:reactions',
  relevance: 'sort:relevance',
  '+1': 'sort:reactions-+1',
  '-1': 'sort:reactions--1',
  rocket: 'sort:reactions-rocket',
  tada: 'sort:reactions-tada',
  eyes: 'sort:reactions-eyes',
  heart: 'sort:reactions-heart',
  smile: 'sort:reactions-smile',
  thinking_face: 'sort:reactions-thinking_face',
}

const sortKeyToAriaLabel = {
  created: 'created on',
  comments: 'total comments',
  updated: 'updated',
  relevance: 'best match',
  reactions: 'total reactions',
  '+1': 'thumbs up reaction',
  '-1': 'thumbs down reaction',
  rocket: 'rocket',
  tada: 'hooray',
  eyes: 'eyes',
  heart: 'heart',
  smile: 'laugh',
  thinking_face: 'confused',
}

export const LABELS = {
  sort: 'Sort',
  Newest: 'Newest',
  Oldest: 'Oldest',
  RecentlyUpdated: 'Recently updated',
  BestMatch: 'Best match',
  sortingLabels: sortOptionsLabels,
  sortDropdownOptionDisplayValues,
  sortDropdownMenuButtonLabels,
  sortDropdownReactionLabels,
  sortKeyToAriaLabel,
  sortKeyToQuery,
  reactions: 'Reactions',
  sortBy: 'Sort by',
  sortByReactions: 'Sort by reactions',
  mostReactions: 'Most reactions',
  Default: 'Default',
  Compact: 'Compact',
  Density: 'Density',
  noResultsTitle: 'No results',
  noResultsDescription: 'Try adjusting your search filters.',
  mostCommented: 'Most commented',
  thumbsUp: '👍 Thumbs up',
  thumbsDown: '👎 Thumbs down',
  rocket: '🚀 Rocket',
  hooray: '🎉 Hooray',
  eyes: '👀 Eyes',
  heart: '❤️ Heart',
  laugh: '😄 Laugh',
  confused: '😕 Confused',
  ascending: 'Ascending',
  descending: 'Descending',
  comments: 'Total comments',
  updated: 'Last updated',
  created: 'Created on',
  totalReactions: 'Total reactions',
  relevance: 'Best match',
}
